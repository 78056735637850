<template>
  <div>
    <div>
      <div class="project-search">
        <div class="flex search-text align-center">
          <div>
            <span>证书名称：</span>
            <input v-model="pageInfo.name" placeholder="证书名称" class="searchInput" />
          </div>
          <div class="marginLeft10">
            <span>职工号/学号：</span>
            <input v-model="pageInfo.code" placeholder="职工号/学号" class="searchInput" />
          </div>
          <div class="marginLeft10">
            <span>考试名称：</span>
            <el-select v-model="pageInfo.examId"
                       filterable
                       class="m-2"
                       placeholder="选择试卷"
                       size="large">
              <el-option v-for="item in examPaperOptions"
                         :key="item.value"
                         :label="item.label"
                         :value="item.value" />
            </el-select>
          </div>
          <!--<div class="marginLeft10">-->
          <!--  <span>开始时间：</span>-->
          <!--  <el-date-picker v-model="pageInfo.startTime"-->
          <!--                  type="datetime"-->
          <!--                  size="large"-->
          <!--                  placeholder="选择考试开始时间"-->
          <!--                  value-format="YYYY-MM-DD HH:mm:ss"/>-->
          <!--</div>-->
          <!--<div class="marginLeft10">-->
          <!--  <span>结束时间：</span>-->
          <!--  <el-date-picker v-model="pageInfo.endTime"-->
          <!--                  type="date"-->
          <!--                  size="large"-->
          <!--                  placeholder="选择考试结束时间"-->
          <!--                  value-format="YYYY-MM-DD"/>-->
          <!--</div>-->
          <button class="searchBtn" @click="search">搜索</button>
          <button class="resetBtn" @click="reset(null)">重置</button>
        </div>
      </div>
      <div class="project-table" style="margin-top: 20px">
        <div class="flex align-center justify-between paddingBTM20">
          <div class="fontSize20gay">证书列表</div>
        </div>
        <el-table :data="tableData" border row-key="id"
                  :header-cell-style="{'text-align':'center','color':'#999999','padding':'16px 0','background-color':'#EBEBEB'}"
                  :cell-style="{'text-align':'center'}">
          <el-table-column prop="id" label="编号" />
          <el-table-column prop="user.code" label="教师职工号/学生学号" />
          <el-table-column prop="name" label="考试名称" />
          <el-table-column prop="endTime" label="考试模式">
            <template #default="{row}">
              <div> {{ row.exam.infinite == 0 ? '单次模式' : '无限模式' }} </div>
            </template>
          </el-table-column>
          <el-table-column prop="exam.startTime" label="开始考试时间" />
          <el-table-column prop="exam.endTime" label="考试结束时间" />
          <el-table-column fixed="right" label="操作" width="150">
            <template #default="scope"><!-- scope.$index 表示 序列号 0、1、2 -->
              <el-button link type="primary" style="color: #3769B9" @click="queryUsers(scope.row)">查看证书</el-button>
            </template>
          </el-table-column>
        </el-table>
        <div class="flex justify-center paddingTD20">
          <!--   分页     -->
          <el-pagination
              :currentPage="pageInfo.page"
              :page-size="pageInfo.limit"
              :page-sizes="[10, 20, 50, 100]"
              :small="true"
              :disabled="false"
              :background="true"
              layout="total, sizes, prev, pager, next, jumper"
              :total="pageInfo.total"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
          />
        </div>
      </div>
    </div>
    <el-dialog v-model="certificatePreviewShow"
               title="证书预览"
               class="info-div700"
               destroy-on-close
               style="padding: 24px;text-align: left">
      <div ref="certificate" class="certificate-outer">
        <img class="bg" src="@/static/img/exam/certificate/bg.png" alt="">
        <img class="hw" src="@/static/img/exam/certificate/hw.png" alt="">
        <div class="content">
          <div class="c-title text-center">合格证书</div>
          <div class="inner-box">
            <div class="c-text c-text-bold">{{ certificateInfo?.user?.name }}，{{ certificateInfo?.user?.username }}</div>
            <div class="c-text">于 {{ dateFormat(certificateInfo?.exam?.startTime, true) }} - {{ dateFormat(certificateInfo?.exam?.endTime, true) }}</div>
            <div class="c-text">参加 <span class="c-text-bold">{{ certificateInfo?.exam?.name }}</span></div>
            <div class="c-text">获得 {{ certificateInfo.score }}分，成绩 <span class="c-text-bold">合格</span>，颁发此证。</div>
            <div class="c-text text-right">教学资源中心</div>
          </div>
          <div class="flex justify-between">
            <div class="c-text">证书编号: {{ certificateInfo.id }}</div>
            <div class="c-text">发证日期: {{ dateFormat(certificateInfo.time) }}</div>
          </div>
        </div>
      </div>
      <template #footer>
      <span class="dialog-footer">
        <el-button size="large" @click="certificatePreviewShow = false">关闭</el-button>
        <el-button type="primary" @click="downLoad" size="large"
                   style="margin-left: 10px;--el-button-border-color: #3769B9;--el-button-bg-color: #3769B9;--el-button-hover-border-color: #3769B9;--el-button-hover-bg-color: #3769B9;">
          下载证书
        </el-button>
      </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import {getCertificateById, getCertificateInfo, getExamAll} from "@/api/examination";
import { floatParse } from "@/utils/common";
import html2canvas from "html2canvas";
export default {
  name: "examResultList",
  data(){
    return{
      tableData:[],
      pageInfo:{
        limit:10,
        page:1,
        total:0,
        // -------------------
        name: '',
        code: '',
        examId: '',
      },
      // 考试选项
      examPaperOptions: [],
      // 证书相关
      certificatePreviewShow: false,
      certificateInfo: {},
    }
  },
  mounted() {
    this.getOption()
    this.getList()
  },
  methods:{
    floatParse,
    /**
     * 下载证书
     */
    downLoad(){
      html2canvas(this.$refs.certificate, {
        useCORS: true,
      }).then((canvas) => {
        const link = canvas.toDataURL("image/jpg");
        this.exportPicture(link);
      });
    },
    /**
     * 导出图片
     */
    exportPicture(link, name = "合格证书") {
      const file = document.createElement("a");
      file.style.display = "none";
      file.href = link;
      file.download = decodeURI(name);
      document.body.appendChild(file);
      file.click();
      document.body.removeChild(file);
    },
    /**
     * 查看证书
     */
    queryUsers(row){
      getCertificateById(row.id).then(res => {
        if (res.success) {
          this.certificateInfo = res.data
          this.certificatePreviewShow = true
        } else {
          this.$root.ElMessage.error(res.message);
        }
      })
    },
    dateFormat(time, hour=false){
      // todo "2023-09-16 00:00:00"
      if (time) {
        let fa = time.split(' ')
        let prefix = fa[0].split('-')
        let suffix = fa[1].split(':')
        if (hour) {
          return `${prefix[0]}年${prefix[1]}月${prefix[2]}日 ${suffix[0]}:${suffix[1]}`
        } else {
          return `${prefix[0]}年${prefix[1]}月${prefix[2]}日`
        }
      }
      return time
    },
    /**
     * 获取考试
     */
    getOption(){
      getExamAll().then(res => {
        res.data.forEach(iter => {
          this.examPaperOptions.push({
            label: iter.name,
            value: iter.id
          })
        })
      })
    },
    /**
     * 重置个别字段
     */
    reset(){
      const whitelist = ['limit', 'page', 'total']
      for (let key in this.pageInfo) {
        if (!whitelist.includes(key)) {
          this.pageInfo[key] = ''
        }
      }
    },
    search(){
      this.pageInfo.limit=10;
      this.pageInfo.page=1;
      this.getList();
    },
    getList(){
      getCertificateInfo(this.pageInfo).then(res=>{
        this.pageInfo.total=parseInt(res.data.total);
        this.tableData=res.data.records;
      })
    },
    handleSizeChange(e){//修改每页显示条数
      this.pageInfo.limit=e;
      this.pageInfo.page=1;
      this.getList();
    },
    handleCurrentChange(e){
      this.pageInfo.page=e;
      this.getList();
    }
  }
}
</script>

<style lang="scss" scoped>
.certificate-outer{
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  gap: 0 0;
  grid-auto-flow: row;

  padding: 16px;
  margin: 0 auto;
  box-sizing: border-box;
  width: 580px;

  .bg {
    grid-area: 1 / 1 / 2 / 2;
    width: 100%;
    height: 358px;
  }
  .hw {
    grid-area: 1 / 1 / 2 / 2;
    margin: 49px auto 0;
    width: 311.71px;
    height: 37.31px;
  }
  .content {
    grid-area: 1 / 1 / 2 / 2;
    padding-bottom: 56px;
    margin: 93.31px auto 0;
    width: 480.8px;
    .c-title{
      font-size: 28px;
      color: #A77868;
    }
    .c-text{
      margin-bottom: 7px;
      font-size: 14px;
      color: #A77868;
    }
    .c-text-bold{
      font-weight: bold;
    }
    .inner-box{
      margin: 7px auto 0;
      width: 450px;
    }
  }
}
.data-title{font-size: 15px;color: #000000}
.data-content{
  font-size: 13px;display: flex;justify-content: space-between;margin-top: 15px;
}
</style>